/* cairo fonts  */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;500;700&display=swap");

/* normailize  */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Cairo";
}
a {
    text-decoration: none;
    color: inherit;
}
.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: auto;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

html[dir="rtl"] input[type="tel"] {
    text-align: right;
}
.App-map {
    width: 100%;
    height: 400px;
}
.text-center {
    text-align: center;
}

.swal2-container {
    z-index: 9999999;
}
